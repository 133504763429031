<template>
  <div class="d-flex justify-content-center flag-pos">
    <input id="radio1" class="mr-2 radio_item" @change="persist" type="radio" v-model="$i18n.locale" :value="'pt'" />
    <label class="label_item" for="radio1">
      <img :class="'flag'" src="@/assets/flags/br.svg" alt="Brasil" />
    </label>

    <input id="radio2" class="mr-2 radio_item" @change="persist" type="radio" v-model="$i18n.locale" :value="'en'" />
    <label class="label_item" for="radio2">
      <img :class="'flag'" src="@/assets/flags/us.svg" alt="EUA" />
    </label>

    <input id="radio3" class="mr-2 radio_item" @change="persist" type="radio" v-model="$i18n.locale" :value="'es'" />
    <label class="label_item" for="radio3">
      <img :class="'flag'" src="@/assets/flags/es.svg" alt="Espanha" />
    </label>
  </div>
</template>
<script>
export default {
  data: () => ({
    locale: '',
  }),
  mounted() {
    if (localStorage.locale) {
      this.locale = localStorage.locale;
    }
  },
  methods: {
    persist() {
      localStorage.setItem('OptLang', this.$i18n.locale);
      /* this.$toast.show({
        title: this.$t('generic-str.success'),
        content: this.$t('options-div.lang-updated'),
        type: 'success',
      });
      console.log(this.$i18n.locale); */
    },
    setLocale() {
      this.$i18n.global.locale = 'en';
    },
  },
};
</script>
<style scoped>
.flag {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
}

.flag-pos {
  gap: 1rem;
}

.radio_item {
  display: none !important;
}

.label_item {
  opacity: 0.1;
}

.radio_item:checked + label {
  opacity: 1;
}

label {
  cursor: pointer;
}
</style>
